div.wallets-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 89px;
  min-height: 100vh;
  background: #f6f8fb;
  @media screen and (max-width: $screen-tablet) {
    padding: 0 39px;
  }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 170px;
    margin-bottom: 42px;
    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
    }
    a {
      display: flex;
      align-items: center;
      background: $gradient-primary;
      height: 56px;
      padding: 0 24px;
      border-radius: 500px;
      color: white;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      transition: 120ms;
      &:hover {
        background: $gradient-hover;
        transform: translateY(3px);
      }
      &:active {
        background: $gradient-active;
        transform: translateY(5px);
      }
      @media screen and (max-width: $screen-mobile) {
        position: fixed;
        bottom: 48px;
        right: 24px;
        z-index: 32;
        width: 56px;
        height: 56px;
        justify-content: center;
        align-items: center;
        padding: unset;
        span {
          display: none;
        }
      }
      svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        @media screen and (max-width: $screen-mobile) {
          margin-right: 0;
        }
        path {
          fill: white;
        }
      }
    }
  }
  main {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-auto-rows: 290px;
    grid-gap: 24px;
    padding-bottom: 96px;
    div.creation-wrapper {
      background: #fbeeff;
      border-radius: 16px;
      border: 2px dashed #963acf;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      cursor: pointer;
      transition: 120ms;
      box-shadow: 0px 32px 62px rgba(88, 81, 103, 0.12), 0px 13px 24px rgba(88, 81, 103, 0.04);
      height: 100%;
      &:hover {
        background: darken(#fbeeff, 2%);
        box-shadow: 0px 32px 62px rgba(88, 81, 103, 0.42), 0px 13px 24px rgba(88, 81, 103, 0.04);
      }
      &:hover {
        background: darken(#fbeeff, 5%);
      }
      svg {
        path {
          fill: #963acf;
        }
      }
    }
    div.wallet-wrapper {
      background: #ffffff;
      overflow: hidden;

      /* SH */

      box-shadow: 0px 32px 62px rgba(88, 81, 103, 0.12), 0px 13px 24px rgba(88, 81, 103, 0.04);
      border-radius: 16px;

      padding: 20px;

      position: relative;
      z-index: 6;

      transform-origin: 60% 50%;

      img {
        height: 104px;
        width: 100%;
        border-radius: 16px;
        object-fit: contain;
      }
      h2 {
        margin-top: 16px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
      }

      p {
        margin-top: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $colour-gray;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        margin-top: 16px;
        height: 48px;
        width: 100%;
        border-radius: 500px;
        border: 1px solid $colour-indigo;
        color: $colour-indigo;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 100% */

        text-align: center;
        transition: 120ms;
        &:hover {
          background: $colour-indigo;
          color: white;
          transform: translateY(3px);
        }
        &:active {
          background: darken($colour-indigo, 5%);
          color: white;
          transform: translateY(5px);
        }
      }
    }
  }
}
