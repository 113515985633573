div.card-container {
  position: relative;
  @media screen and (max-width: $screen-mobile) {
    margin-top: 86px;
  }
  &.login {
    div.card-segment-wrapper {
      padding-top: 148px;
    }
    &.success::before {
      animation: scooter_leave 800ms ease-in-out forwards;
    }
    &::before {
      width: 200px;
      height: 200px;
      content: '';
      display: block;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background: url('../../images/banner-login.png');
      background-size: contain;
      z-index: 6;
      animation: scooter_appear 800ms ease-out forwards;
    }
  }
  &.register {
    &.success::before {
      animation: rocket_leave 800ms ease-in-out forwards;
    }
    &::before {
      z-index: 1;
      width: 200px;
      height: 200px;
      content: '';
      display: block;
      top: 100%;
      left: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      background: url('../../images/banner-register.png');
      background-size: contain;
      animation: rocket_enter 800ms ease-out forwards;
    }
  }
  div.card-segment-wrapper {
    text-align: center;
    padding: 64px 42px;
    width: calc(100vw - 64px);
    max-width: 500px;
    border-radius: 16px;
    position: relative;
    z-index: 3;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(239, 241, 255, 0.35) 87.99%);
    backdrop-filter: blur(32px);
    @media screen and (max-width: $screen-mobile) {
      padding: 48px 24px;
    }

    h1 {
      background: linear-gradient(to right, #4b05a4, #ff9c64);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-size: 36px;
      line-height: 100%;
      @media screen and (max-width: $screen-mobile) {
        font-size: 30px;
        line-height: 100%;
      }
    }
    h2 {
      font-size: 20px;
      line-height: 30px;
      color: $colour-gray;
      margin-top: 28px;
      margin-bottom: 32px;
      @media screen and (max-width: $screen-mobile) {
        font-size: 18px;
        line-height: 27px;
        text-align: center;
      }
    }
    a {
      width: 100%;
      height: 56px;
      line-height: 64px;
      display: flex;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      /* identical to box height, or 100% */

      text-align: center;

      color: #ffffff;
      border-radius: 16px;

      justify-content: center;
      align-items: center;
      transition: 120ms;
      cursor: pointer;
      &:hover {
        background: $gradient-hover;
        transform: translateY(3px);
      }
      &:active {
        background: $gradient-active;
        transform: translateY(5px);
      }
      &::before {
        width: 24px;
        height: 24px;
        background: url('../../images/logo-icon.png');
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        content: '';
        margin-right: 12px;
      }
    }
  }
}

@keyframes scooter_appear {
  from {
    transform: translate(-100vw, -50%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
@keyframes scooter_leave {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(100vw, -50%);
  }
}
@keyframes rocket_enter {
  0% {
    transform: translate(calc(-50% - 100vh), calc(-50% + 100vh));
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes rocket_leave {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(calc(-50% + 100vh), calc(-50% - 100vh));
  }
}
