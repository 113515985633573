div.username-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $colour-bg;
  main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 160px;
    padding-bottom: 64px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px;
    @media screen and (max-width: $screen-tablet) {
      padding-left: 64px;
    }
    @media screen and (max-width: $screen-mobile) {
      padding-left: 0;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
    }
    div.image-wrapper {
      width: 382px;
      height: 463px;
      display: block;
      // position: absolute;
      // left: 914px;
      margin-left: auto;
      z-index: 2;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0;
        transition-delay: 260ms;
        &:last-child {
          opacity: 0;
        }
      }

      &.success {
        animation: slide 640ms ease-in-out forwards;
        img {
          &:last-child {
            opacity: 1;
          }
          &:first-child {
            opacity: 0;
          }
        }
      }
      @media screen and (max-width: $screen-tablet) {
        width: 319px;
        height: 315px;
      }
      @media screen and (max-width: $screen-mobile) {
        width: calc(100vw - 160px);
        height: calc(100vw - 160px);
        grid-row: 1;
        margin: auto;
      }
    }
    &::after {
      width: 769px;
      height: 100vh;
      background: url('../../images/oval-username.svg');
      background-size: contain;
      background-position: left;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      content: '';
      background-repeat: no-repeat;
      z-index: 1;
      pointer-events: none;
      @media screen and (max-width: $screen-tablet) {
        background-image: url('../../images/oval-username-full.svg');
        height: 100vh;
        width: calc(100vw - 180px);
        background-size: contain;
        background-position: left top;
      }
      @media screen and (max-width: $screen-mobile) {
        max-width: calc(100vw - 64px);
        background-position: top right;
        background-size: contain;
        height: 652px;
        left: -32px;
      }
    }
    &.success {
      &::before {
        @keyframes slideout {
          0% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(100vw);
            // background: url('../../../images/karate.png');
          }
          100% {
            // background: url('../../../images/karate.png');
            transform: translateX(0);
          }
        }
        animation: slideout 1000ms ease-in-out forwards;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      z-index: 3;
      width: 480px;
      @media screen and (max-width: $screen-mobile) {
        width: calc(100vw - 64px);
        margin: auto;
      }
      h1 {
        font-weight: bold;
        font-size: 48px;
        line-height: 120%;
        background: linear-gradient(to right, #4b05a4, #ff9c64);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        max-width: 360px;
        @media screen and (max-width: $screen-tablet) {
          font-size: 32px;
          line-height: 140%;
        }
        @media screen and (max-width: $screen-mobile) {
          font-size: 30px;
          line-height: 130%;
        }
      }
      div.row {
        display: flex;
        align-items: center;
        margin-top: 48px;
        @media screen and (max-width: $screen-tablet) {
          margin-top: 40px;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-template-rows: repeat(2, 1fr);
          grid-template-areas: 'input input' 'b c';
          grid-gap: 48px 0;
        }
        @media screen and (max-width: $screen-tablet) {
          margin-top: 24px;
        }

        button {
          height: 56px;
          padding: 0 32px;
          border-radius: 16px;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          &.primary {
            color: white;
          }
          &:disabled {
            background: linear-gradient(110.77deg, #d0c8c5 -142.14%, #bfb4c6 144.12%);
            color: $colour-gray;
          }

          &:not(.primary) {
            background: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100vw);
    // background: url('../../../images/karate.png');
  }
  100% {
    // background: url('../../../images/karate.png');
    transform: translateX(0);
  }
}
