div.profile-wrapper {
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 26px 32px;
  grid-template-areas: 'nav x' 'filter main';
  width: 100vw;
  padding-top: 215px;
  background: rgb(242, 247, 253);
  padding-left: 160px;
  padding-right: 160px;
  @media screen and (max-width: $screen-tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'nav filter' 'main main';
    padding-left: 39px;
    padding-right: 39px;
    padding-top: 160px;
  }
  nav {
    grid-area: nav;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $screen-tablet) {
      max-width: 260px;
    }
    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
    }
    h1 {
      margin: 0;
      background: linear-gradient(to right, #4b05a4 -142.14%, #ff9c64 142.14%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-size: 20px;
    }
    span.copy {
      padding: 8px 12px;
      border-radius: 500px;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;

      letter-spacing: 0.02em;
      text-transform: uppercase;

      color: #ffffff;
      cursor: pointer;
      transition: 120ms;
      position: relative;
      &:hover {
        transform: translateY(3px);
        background: $gradient-hover;
      }
      &:active {
        transform: translateY(5px);
        background: $gradient-active;
      }
      &.copied {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: $gradient-primary;
          display: block;
          content: '';
          border-radius: 300px;
          opacity: 0.6;
          animation: copy 480ms ease-out forwards;
        }
      }
    }
  }
  article {
    grid-area: filter;
    height: 100%;
    position: relative;
    z-index: 32;
    div.content {
      position: sticky;
      top: 160px;
      @media screen and (max-width: $screen-tablet) {
        justify-content: flex-end;
      }
      @media screen and (max-width: $screen-tablet) {
        display: flex;
        gap: 16px;
        div.search-wrapper {
          flex: 1;
        }
      }
    }
  }
  main {
    grid-area: main;
    // position: relative;
    div.placeholder {
      position: fixed;
      // z-index: -2;
      background: red;
      background: #fefaff;
      box-shadow: 0px 32px 62px rgba(88, 81, 103, 0.12), 0px 13px 24px rgba(88, 81, 103, 0.04);
      border-radius: 24px;
      // z-index: 1;
      border: 2px dashed #963acf;
    }
    div {
      &.grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

        grid-gap: 24px;
        padding-bottom: 96px;
      }
      &.empty {
        div.empty-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-bottom: 120px;
          img {
            width: 309px;
            height: 309px;
          }
          h2 {
            font-weight: bold;
            font-size: 32px;
            line-height: 100%;
            margin-top: 16px;
          }
          a {
            padding: 24px 64px;
            border-radius: 500px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: white;
            display: flex;
            align-items: center;
            margin-top: 48px;

            svg {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}

@keyframes copy {
  from {
    opacity: 0.6;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
