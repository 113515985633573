@tailwind base;
@tailwind utilities;

@import 'variables';
@import 'components/main', 'pages/main';

body,
html {
  color: $colour-black;
  font-family: $title-font;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    content: '';
    background: $colour-bg;
    z-index: -12;
    pointer-events: none;
  }
  * {
    box-sizing: border-box;
  }
}

*.bg-gradient {
  background: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);
}
