div.search-wrapper {
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(231, 232, 241, 0.15);
  border-radius: 16px;
  display: flex;
  height: 56px;
  position: relative;
  margin-bottom: 24px;
  @media screen and (max-width: $screen-mobile) {
    max-width: 56px;
  }
  svg {
    position: absolute;
    left: 20px;
    top: 16px;
    @media screen and (max-width: $screen-mobile) {
      left: 16px;
    }
  }
  input {
    line-height: 56px;
    padding-left: 60px;
    width: 100%;
    appearance: none;
    background: none;
    border: none;
    outline: none;
    @media screen and (max-width: $screen-mobile) {
      display: none;
    }
  }
}
