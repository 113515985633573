div.filter-wrapper {
  padding: 32px;

  background: #ffffff;
  box-shadow: 0px 13px 24px rgba(88, 81, 103, 0.04);
  border-radius: 16px;
  position: relative;
  z-index: 32;
  @media screen and (max-width: $screen-tablet) {
    width: 56px;
    height: 56px;
    padding: 16px;
    cursor: pointer;
  }
  div.title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    @media screen and (max-width: $screen-tablet) {
      margin-bottom: 0;
      cursor: pointer;
    }
    span {
      padding-left: 12px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      @media screen and (max-width: $screen-tablet) {
        display: none;
      }
    }
  }
  div.select-wrapper {
    @media screen and (max-width: $screen-tablet) {
      position: absolute;
      top: calc(100% + 16px);
      right: 0;
      background: #ffffff;
      width: 280px;
      max-width: calc(100vw - 64px);
      z-index: 6;
      padding: 20px;
      border-radius: 16px;
      box-shadow: 0px 13px 24px rgba(88, 81, 103, 0.04);
      transition: 320ms;
      visibility: visible;
      opacity: 1;
      &.collapsed {
        transform: translateY(-8px);
        opacity: 0;
        visibility: hidden;
      }
    }
    div.option-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      cursor: pointer;
      div.knob-wrapper {
        width: 24px;
        height: 24px;
        background: linear-gradient(110.77deg, rgba(88, 83, 109, 0.6) -142.14%, rgba(88, 83, 109, 0.6) 144.12%);
        border-radius: 500px;
        position: relative;
        transition: 320ms;
        &::after {
          position: absolute;
          top: 2px;
          left: 2px;
          bottom: 2px;
          right: 2px;
          border-radius: 50px;
          background: white;
          display: block;
          content: '';
          pointer-events: none;
        }
        div.knob {
          background: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);
          width: 16px;
          height: 16px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          border-radius: 50px;
          opacity: 0;
          transition: 320ms;
        }
      }
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        padding-left: 12px;
      }
      &.active {
        div.knob-wrapper {
          background: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);
          div.knob {
            opacity: 1;
          }
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
