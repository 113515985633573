div.hero-wrapper {
  padding-top: 167px;
  text-align: center;
  // background: linear-gradient(
  //   196.54deg,
  //   transparentize(#8ca5fd, 0.6) 22.27%,
  //   transparentize(#d6e0fa, 0.6) 55.28%,
  //   rgba(255, 255, 255, 0) 75.64%
  // );

  position: relative;
  background-position: bottom;
  padding-bottom: 96px;
  @media screen and (max-width: $screen-mobile) {
    padding-bottom: 66px;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% + 120px);
    width: 100%;
    z-index: -1;
    opacity: 0.6;
  }
  h2 {
    background: linear-gradient(to right, #4b05a4, #ff9c64);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 80px;
    line-height: 120px;
    @media screen and (max-width: $screen-mobile) {
      font-size: 56px;
      line-height: 100%;
    }
  }
  p {
    font-size: 32px;
    line-height: 48px;
    margin-top: -9px;

    color: $colour-gray;

    strong {
      font-weight: bold;
      color: $colour-black;
      position: relative;

      &::after {
        top: 50%;
        left: calc(50% + 4px);
        transform: translate(-50%, -50%);
        width: 155px;
        height: 75px;
        position: absolute;
        display: block;
        content: '';
        background: url('../../images/stroke.svg');
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
        @media screen and (max-width: $screen-tablet) {
          width: 77.12px;
          height: 33.63px;
        }
      }
    }

    @media screen and (max-width: $screen-mobile) {
      margin: 0 32px;
      font-size: 20px;
      line-height: auto;
      margin-top: 24px;
    }
  }
  a {
    margin-top: 58px;
    padding: 20px 52px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;

    color: #ffffff;

    box-shadow: 0px 14px 32px rgba(150, 58, 207, 0.24);
    @media screen and (max-width: $screen-mobile) {
      margin-top: 24px;
    }
    transition: 120ms;
    &:hover {
      transform: translatey(3px);
      background: $gradient-hover;
    }
    &:active {
      transform: translatey(5px);
      background: $gradient-active;
    }
  }
  img {
    width: 422px;
    height: 390px;
    margin-top: 36px;
    max-width: 100vw;
    max-height: 100vw;
    object-fit: contain;
  }
}
