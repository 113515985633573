div.logos-wrapper {
  margin-bottom: 208px;
  position: relative;
  z-index: 3;
  @media screen and (max-width: $screen-mobile) {
    padding: 0 32px;
    margin-bottom: 94px;
  }
  &::before {
    position: absolute;
    right: 0;
    top: 92px;
    width: 440px;
    height: 995px;
    display: block;
    background: url('../../images/oval-1.svg');
    background-size: contain;
    // background-position: right;
    content: '';
    z-index: 0;
    background-repeat: no-repeat;

    @media screen and (max-width: $screen-mobile) {
      max-width: 50vw;
      top: 32px;
    }
    @media screen and (max-width: $screen-tablet) {
      background-position-x: right;
      background-position-y: top;
      height: 691px;
      top: 294px;
    }
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;
    @media screen and (max-width: $screen-tablet) {
      font-size: 32px;
      line-height: 48px;
    }
    @media screen and (max-width: $screen-mobile) {
      font-size: 30px;
      line-height: 120%;
      text-align: left;
    }
  }
  p {
    margin: 0;
    font-size: 24px;
    line-height: 36px;
    color: $colour-gray;
    @media screen and (max-width: $screen-mobile) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
      text-align: left;
    }
    @media screen and (max-width: $screen-tablet) {
      margin-top: 16px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  & > div {
    margin-top: 71px;

    @media screen and (max-width: $screen-tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin-top: 64px;
    }
    @media screen and (max-width: $screen-mobile) {
      margin: 0 -8px;
      margin-top: 40px;
    }
    div {
      position: relative;
      margin: 0 -8px;
      transition: 420ms;

      &:hover {
        transform: translateY(-12px);
      }
      & > span {
        display: block;
        padding: 2px 10px;
        border-radius: 50px;
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f0e3ff;
        span {
          white-space: nowrap;
          letter-spacing: -2%;
          background: linear-gradient(110.77deg, #ffd0bc -242.14%, #963acf 244.12%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @media screen and (max-width: $screen-mobile) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
