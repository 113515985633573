header.header-wrapper {
  padding: 25px 160px;
  z-index: 512;
  @media screen and (max-width: $screen-tablet) {
    display: none;
  }
  div.actions {
    display: flex;
    a {
      display: block;
      padding: 0 48px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 500px;
      height: 48px;
      display: flex;
      align-items: center;
      &.primary {
        color: white;
      }
    }
  }
}
