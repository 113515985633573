div.logo-wrapper {
  div {
    width: 36px;
    height: 36px;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
  h1 {
    font-family: $title-font;
    padding-left: 9px;
    font-size: 27px;
    font-weight: 700;
  }
}
