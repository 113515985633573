div.cards-wrapper {
  width: calc(100vw - 320px);
  margin: auto;
  margin-bottom: 200px;
  position: relative;
  z-index: 3;
  @media screen and (max-width: $screen-tablet) {
    width: calc(100vw - 41px);
    margin-bottom: 140px;
  }
  @media screen and (max-width: $screen-mobile) {
    width: calc(100vw - 32px);
    margin-bottom: 78px;
  }
  &::after {
    width: 504px;
    height: 865px;
    position: absolute;
    display: block;
    content: '';
    left: -160px;
    top: 379px;
    background: url('../../images/oval-2.svg');
    z-index: -2;
    background-repeat: no-repeat;
    pointer-events: none;

    @media screen and (max-width: $screen-mobile) {
      width: 50vw;
      top: -212px;
      background-position-x: right;
    }
    @media screen and (max-width: $screen-tablet) {
      background-position-x: left;
      background-position-y: top;
      left: -41px;
      top: 480px;
      background-size: contain;
      height: 600px;
    }
  }
  h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 80px;
    @media screen and (max-width: $screen-mobile) {
      margin-left: 16px;
      margin-bottom: 40px;
      font-size: 30px;
    }
    @media screen and (max-width: $screen-tablet) {
      font-size: 32px;
      line-height: 48px;
      margin-left: 31px;
    }
  }
  div.title {
    margin-bottom: 24px;
    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
      align-items: flex-start;
      div.user {
        margin-bottom: 8px;
      }
    }
    div.user {
      img {
        width: 56px;
        height: 56px;
        border-radius: 500px;
        margin-right: 12px;
        object-fit: cover;
      }
      span {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
      }
    }
    span.value {
      border: 2.6px solid $colour-green;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 16px;
      font-weight: bold;
      font-size: 20px;
      line-height: 44px;
      color: $colour-green;
    }
    span.price {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: $colour-gray;
      margin-left: 16px;
      display: block;
    }
  }
  div.body {
    // background: #eff8ff;
    // padding: 32px;
    border-radius: 20px;
    width: 100%; //calc(100vw - 320px);
    max-width: 1056px;
    margin: auto;
    @media screen and (max-width: $screen-tablet) {
      width: 100%;
      padding: 24px;
    }
    @media screen and (max-width: $screen-mobile) {
      width: 100%;
      padding: 16px;
      padding-bottom: 28px;
    }
    div.list {
      gap: 24px;
      @media screen and (max-width: $screen-mobile) {
        flex-direction: column;
      }

      div.card-wrapper {
        border-radius: 24px;
        box-shadow: 0px 32px 62px rgba(88, 81, 103, 0.12), 0px 13px 24px rgba(88, 81, 103, 0.04);
        padding: 22px;
        max-width: 400px;
        transition: 320ms;
        &:hover {
          box-shadow: 0px 32px 62px rgba(88, 81, 103, 0.42), 0px 13px 24px rgba(88, 81, 103, 0.04);
        }
        @media screen and (max-width: $screen-tablet) {
          &:last-child {
            display: none;
          }
        }
        @media screen and (max-width: $screen-mobile) {
          &:not(:first-child) {
            display: none;
          }
        }
        & > img {
          height: 240px;
          width: 100%;
          object-fit: cover;
          border-radius: 16px;
        }
        h3 {
          margin-top: 16px;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
        }
        div.user {
          margin-top: 16px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 500px;
            object-fit: cover;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-left: 13px;
          }
        }
        div.footer {
          margin-top: 16px;
          img {
            border-radius: 500px;
          }
          span {
            padding-left: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
        div.currency {
          margin-top: 12px;
          span.value {
            color: $colour-green;
            padding: 6px;
            font-weight: bold;
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;

            border: 2px solid $colour-green;
            // box-sizing: border-box;
            border-radius: 4px;
          }
          span.price {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $colour-gray;
            padding-left: 8px;
          }
        }
      }
    }
  }
}
