@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// Misc
$title-font: 'Poppins', sans-serif;

// Colours
$colour-black: #2d294c;
$colour-gray: rgba(88, 83, 109, 0.9);
$colour-indigo: #963acf;
$colour-green: #2cbf93;
$colour-coral: #ff6752;
$colour-bg: rgb(242, 247, 253);

$gradient-primary: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);
$gradient-hover: linear-gradient(110.77deg, darken(#ffd0bc, 5%) -142.14%, darken(#963acf, 5%) 144.12%);
$gradient-active: linear-gradient(110.77deg, darken(#ffd0bc, 10%) -142.14%, darken(#963acf, 10%) 144.12%);

// Screen sizes
$screen-mobile: 500px;
$screen-tablet: 1000px;
