header.mobile-header-wrapper {
  padding: 25px 160px;
  z-index: 512;
  transition: 120ms;
  border-bottom: 1px solid transparent;
  &.scrolled {
    backdrop-filter: blur(8px);
    background: transparentize($colour-bg, 0.4);
    border-color: transparentize($colour-indigo, 0.9);
  }
  &.dashboard {
    padding-left: 76px;
    padding-right: 76px;
    @media screen and (max-width: $screen-tablet) {
      padding-left: 39px;
      padding-right: 39px;
    }
  }
  div.actions {
    display: flex;
    a {
      display: block;
      padding: 0 48px;
      font-size: 16px;
      border-radius: 500px;
      height: 48px;
      display: flex;
      align-items: center;
      transition: 120ms;
      cursor: pointer;

      &[href] {
        font-weight: 600;
      }
      &:hover {
        transform: translatey(3px);
      }
      &:active {
        transform: translatey(5px);
      }
      &.primary {
        color: white;
        &:hover {
          background: $gradient-hover;
        }
        &:hover {
          background: $gradient-active;
        }
      }
      &.skip {
        height: 48px;
        padding: 16px 32px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $colour-gray;
        background: #ffffff;
        border-radius: 100px;
      }
    }
  }
  div.hamburger {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;
    span {
      display: block;
      width: 20px;
      height: 3px;
      background: linear-gradient(110.77deg, $colour-gray -142.14%, $colour-gray 144.12%);
      border-radius: 50px;
      position: absolute;
      transition: 320ms;
      &:first-of-type {
        transform: rotate(45deg);
      }
      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
    &.collapsed {
      span {
        background: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);

        &:first-of-type {
          transform: translateY(-5px);
        }
        &:last-of-type {
          transform: translateY(5px);
        }
      }
    }
  }
  @media screen and (max-width: $screen-tablet) {
    padding: 25px 72px;
    div.actions:not(.mobile) {
      display: none;
    }
    div.hamburger {
      display: flex;
    }
  }
  @media screen and (max-width: $screen-mobile) {
    padding: 25px 32px;
  }
}

nav.mobile-menu-wrapper {
  position: fixed;
  top: 112px;
  right: 72px;
  max-width: calc(100vw - 64px);
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 16px;
  gap: 16px;
  z-index: 128;
  border-radius: 20px;
  transition: 320ms;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  @media screen and (max-width: $screen-mobile) {
    right: 32px;
  }

  transform: translateY(-48px);
  opacity: 0;
  visibility: hidden;
  @media screen and (max-width: $screen-tablet) {
    &:not(.collapsed) {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
  a.regular {
    display: flex;
    align-items: center;
    height: 48px;
    svg {
      width: 24px;
      height: 24px;
      margin-left: 20px;
      margin-right: 12px;
    }
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: $colour-black;
    }
  }
  a.primary,
  a.secondary {
    height: 40px;
    width: 100%;
    border-radius: 500px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fcfcfd;
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.secondary {
    color: $colour-black;
    border: 2px solid transparentize($colour-gray, 0.84);
    box-sizing: border-box;
  }
}
