div.wallet-creation-page {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: $colour-bg;
  main {
    padding: 120px 0;
    display: flex;

    align-items: flex-start;
    justify-content: space-between;
    width: calc(100vw - 240px);
    max-width: 1200px;
    margin: auto;
    gap: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    @media screen and (max-width: $screen-tablet) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 200px 72px;
      width: fit-content;
      height: fit-content;
      div.col:first-of-type {
        img {
          display: none;
        }
      }
    }
    @media screen and (max-width: $screen-mobile) {
      display: flex;
      align-items: flex-start;
      padding: 152px 32px;
    }
    div.col:first-of-type {
      h1 {
        background: linear-gradient(to right, #4b05a4, #ff9c64);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        font-size: 48px;
        line-height: 120%;
        margin-bottom: 48px;
        max-width: 420px;
        @media screen and (max-width: $screen-tablet) {
          font-weight: bold;
          font-size: 32px;
          line-height: 140%;
        }
        @media screen and (max-width: $screen-mobile) {
          font-weight: bold;
          font-size: 30px;
          line-height: 130%;
        }
      }
      img {
        width: 355px;
        height: 338px;
        object-fit: contain;
        transform: scaleX(-1);
      }
    }

    div.selector {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 491px;
      height: 512px;
      flex-shrink: 0;
      @media screen and (max-width: $screen-mobile) {
        width: 100%;
      }
      h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 40px;
        margin-bottom: 32px;
        padding-left: 24px;
        @media screen and (max-width: $screen-tablet) {
          padding-left: 0;
          font-weight: bold;
          font-size: 24px;
          line-height: 120%;
        }
        @media screen and (max-width: $screen-mobile) {
          padding: 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
        }
      }
      div.row {
        display: flex;
        margin-bottom: 32px;
        @media screen and (max-width: $screen-tablet) {
          margin-left: -24px;
        }
        @media screen and (max-width: $screen-mobile) {
          margin-left: -16px;
        }
        div.option-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          cursor: pointer;

          img {
            width: 152px;
            height: 164px;
            @media screen and (max-width: $screen-mobile) {
              width: calc(152px * 0.72);
              height: calc(164px * 0.72);
            }
          }
          span {
            text-align: center;
            position: absolute;
            top: calc(100% - 24px);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            max-width: 100%;
            margin: 0 24px;
            @media screen and (max-width: $screen-mobile) {
              top: calc(100% - 16px);
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              margin: 0;
            }
          }
        }
      }
      div.bottom {
        justify-content: center;
        display: none;
        margin-top: 36px;
        @media screen and (max-width: $screen-mobile) {
          display: flex;
        }
        a {
          padding: 12px 24px;
          border-radius: 500px;
          background: white;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: $colour-gray;
        }
      }
      div.entry {
        display: flex;
        padding-left: 24px;
        gap: 16px;
        @media screen and (max-width: $screen-mobile) {
          padding-left: 0;
          flex-direction: column;
          div.input-wrapper {
            margin-right: 0;
            input {
              margin: 0;
              width: 100%;
            }
          }
          & > * {
            width: 100%;
          }
        }
      }
    }
  }
  button {
    height: 56px;
    padding: 0 32px;
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    &.primary {
      color: white;
    }
    &:disabled {
      background: linear-gradient(110.77deg, #d0c8c5 -142.14%, #bfb4c6 144.12%);
      color: $colour-gray;
    }

    &:not(.primary) {
      background: linear-gradient(110.77deg, #ffd0bc -142.14%, #963acf 144.12%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
