footer.footer-wrapper {
  margin: 0 135px;
  position: relative;
  @media screen and (max-width: $screen-mobile) {
    margin: 0 32px;
  }
  &::after {
    width: 790px;
    height: 651px;
    position: absolute;
    left: -135px;
    bottom: 0;
    display: block;
    content: '';
    background: url('../../images/oval-4.svg');
    z-index: -2;
    background-size: cover;
    pointer-events: none;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100vw;
      left: -32px;
      background-position-x: 50%;
      bottom: 120px;
    }
  }
  div.content {
    padding-bottom: 69.5px;
    @media screen and (max-width: $screen-tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-template-areas: 'a a a' 'b c d';
      grid-gap: 48px 64px;
    }
    @media screen and (max-width: $screen-mobile) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      grid-template-areas: 'a a' 'b c' 'd e';
      grid-gap: 48px 40px;
    }
    div.col {
      span {
        font-size: 24px;
        margin-bottom: 28px;
        font-weight: 500;
        @media screen and (max-width: $screen-mobile) {
          font-size: 18px;
          line-height: 132%;
          margin-bottom: 20px;
        }
      }
      a {
        font-size: 18px;
        line-height: 40px;
        color: transparentize(#58536d, 0.1);
        @media screen and (max-width: $screen-mobile) {
          font-size: 14px;
          line-height: 42px;
        }
      }
      div.socials {
        display: flex;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background: white;
          border-radius: 500px;
          cursor: pointer;
        }
      }
    }

    div.flex-2 {
      flex: 2;
      @media screen and (max-width: $screen-tablet) {
        grid-area: a;
      }
      h2 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 48px;
        line-height: 24px;
        @media screen and (max-width: $screen-mobile) {
          font-size: 32px;
          margin-bottom: 24px;
        }
      }
      span {
        line-height: auto;
        font-size: 16px;
        margin: 0;
        color: $colour-gray;
        @media screen and (max-width: $screen-mobile) {
          font-size: 16px;
          line-height: 24px;
          font-weight: normal;
        }
      }
    }
  }
  div.bottom {
    padding: 22.5px 0;
    margin-bottom: 22.5px;
    text-align: center;
    border-top: 1px solid rgba(105, 120, 131, 0.16);
    span {
      font-size: 16px;
      color: $colour-gray;
    }
  }
}
