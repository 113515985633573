div.start-wrapper {
  text-align: center;
  margin-bottom: 166px;
  position: relative;
  z-index: 4;
  &::after {
    top: 112px;
    right: 0;
    position: absolute;
    display: block;
    content: '';
    height: 829px;
    width: 400px;
    background-size: cover;
    z-index: -2;
    background: url('../../images/oval-3.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    pointer-events: none;
    @media screen and (max-width: $screen-mobile) {
      transform: translate(calc(100% - 80px));
      top: -100px;
    }
    @media screen and (max-width: $screen-tablet) {
      height: 615px;
      top: 244px;
    }
  }

  h2 {
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;
    @media screen and (max-width: $screen-tablet) {
      font-size: 32px;
      line-height: 48px;
    }
  }
  p {
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    margin-top: 8px;
    color: $colour-gray;
    max-width: 540px;
    @media screen and (max-width: $screen-tablet) {
      font-size: 20px;
      line-height: 30px;
    }
    strong {
      font-weight: bold;
      color: $colour-black;
      position: relative;

      &::after {
        top: 50%;
        left: calc(50% + 4px);
        transform: translate(-50%, -50%);
        width: 110px;
        height: 54px;
        position: absolute;
        display: block;
        content: '';
        background: url('../../images/stroke.svg');
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
        @media screen and (max-width: $screen-tablet) {
          width: 77.12px;
          height: 33.63px;
        }
      }
    }
  }
  a {
    margin-top: 40px;
    padding: 20px;
    width: 240px;
    border-radius: 20px;
    background: linear-gradient(97.39deg, rgba(255, 208, 188, 0.2) -21.89%, rgba(150, 58, 207, 0.2) 134.13%);
    color: #ad5bcc;
    font-size: 16px;
    position: relative;
    z-index: 2;
    transition: 120ms;
    &:hover {
      transform: translatey(3px);
      background: linear-gradient(
        97.39deg,
        darken(rgba(255, 208, 188, 0.2), 15%) -21.89%,
        darken(rgba(150, 58, 207, 0.2), 15%) 134.13%
      );
    }
    &:active {
      transform: translatey(5px);
      background: linear-gradient(
        97.39deg,
        darken(rgba(255, 208, 188, 0.2), 20%) -21.89%,
        darken(rgba(150, 58, 207, 0.2), 20%) 134.13%
      );
    }
  }
  @media screen and (max-width: $screen-mobile) {
    padding: 0 32px;
    text-align: left;
    h2 {
      font-size: 30px;
      line-height: 45px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
      margin-top: 24px;
      strong::after {
        width: calc(110px * 0.8);
        height: calc(54px * 0.8);
        pointer-events: none;
      }
    }
    a {
      text-align: center;
      width: calc(100vw - 64px);
    }
  }

  img {
    width: 359px;
    height: 359px;
    margin-top: 40px;
    transform: translateX(-10px);
  }
}
