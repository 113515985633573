div.features-wrapper {
  padding-left: 160px;
  margin-bottom: 266px;
  position: relative;
  width: 100vw;
  z-index: 3;
  overflow: hidden;
  @media screen and (max-width: $screen-tablet) {
    padding-left: 72px;
    margin-bottom: 204px;
  }
  @media screen and (max-width: $screen-mobile) {
    padding: 0 32px;
    margin-bottom: 148px;
  }
  h2 {
    font-size: 40px;
    font-weight: bold;
    list-style: 60px;
    @media screen and (max-width: $screen-mobile) {
      font-size: 30px;
      line-height: 120%;
    }
    @media screen and (max-width: $screen-tablet) {
      font-size: 32px;
      line-height: 48px;
    }
  }
  p {
    margin-top: 16px;
    font-size: 24px;
    line-height: 36px;
    color: $colour-gray;
    margin-bottom: 88px;
    @media screen and (max-width: $screen-mobile) {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
    @media screen and (max-width: $screen-tablet) {
      max-width: 380px;
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      max-width: 380px;
    }
  }
  img.ninja {
    width: 521px;
    height: 526px;
    position: absolute;
    left: max(900px, calc(100vw - 440px));
    top: 200px;
    @media screen and (max-width: $screen-tablet) {
      top: 0;
      left: max(512px, calc(100vw - 260px));
      width: 316px;
      height: 321px;
    }
    @media screen and (max-width: $screen-mobile) {
      position: relative;
      // display: none;
      top: 0;
      left: 0;
      max-width: calc(100vw - 64px);
      max-height: calc(100vw - 64px);
      object-fit: contain;
      margin-bottom: 40px;
    }
  }
  div.feature-list {
    display: grid;
    grid-template-columns: repeat(2, 350px);
    grid-template-rows: 1fr 1fr;
    grid-gap: 49px 32px;
    @media screen and (max-width: $screen-tablet) {
      grid-template-columns: repeat(2, 1fr);
      width: calc(100vw - 144px);
    }
    @media screen and (max-width: $screen-mobile) {
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 24px;
    }
    div.feature-wrapper {
      div.icon-wrapper {
        width: 96px;
        height: 96px;
        border-radius: 16px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 64px;
          height: 64px;
          object-fit: contain;
        }
      }
      h3 {
        margin-top: 32px;
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;

        @media screen and (max-width: $screen-mobile) {
          font-size: 24px;
          line-height: 36px;
        }
        @media screen and (max-width: $screen-tablet) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      p {
        margin: 0;
        margin-top: 6px;
        font-size: 16px;
        line-height: 27px;
        @media screen and (max-width: $screen-tablet) {
          font-size: 16px;
          line-height: 24px;
        }
        @media screen and (max-width: $screen-mobile) {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
}
